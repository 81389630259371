// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import LanguageSwitcher from './LanguageSwitcher';

const Navigation = () => {
    const { t } = useTranslation();
    const { lang } = useParams(); // Get current language parameter
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    const [user, setUser] = useState(null);

    useEffect(() => {
        // Subscribe to auth state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    // Sign out function
    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (err) {
            console.error('Error signing out: ', err);
        }
    };

    return (
        <Navbar sticky="top" className=" " expand="lg" style={{ backgroundColor: '#00FFFF', color: '#f8f9fa' }}>
            <Container fluid>
                <Navbar.Brand as={Link} to={`/${lang || 'en'}`} className="d-flex align-items-center">
                    <img
                        src="/android-chrome-192x192.png"
                        width="50"
                        height="50"
                        className="d-inline-block align-top me-2"
                        alt="App Logo"
                    />
                    <span>
                        {t('global.app_name')}
                        <small className="d-none d-sm-block" style={{ fontSize: 12 }}>
                            {t('global.app_slogan')}
                        </small>
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={`/${lang || 'en'}`} className='mr-2'>
                            <i className="fas fa-home"></i> {t('global.nav_home')}
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/findpets`} className='mr-2'>
                            <i className="fas fa-search"></i> {t('global.nav_find')}
                        </Nav.Link>

                        <Nav.Link as={Link} to={`/${lang || 'en'}/rehome-sell-pets`} className='mr-2' >
                            <i className="fas fa-plus-circle"></i> {t('Create Rehome / Sell Ad')}
                        </Nav.Link>

                        <Nav.Link as={Link} to={`/${lang || 'en'}/breed-scanner`} className='mr-2'>
                            <i className="fas fa-brain"></i>  {t('global.nav_breed')}


                        </Nav.Link>

                        <Nav.Link as={Link} to={`/${lang || 'en'}/pet-social`} className='mr-2'>
                            <i className="fas fa-users"></i> {t('Pet Social')}
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/pet-advisor`} className='mr-2'>
                            <i className="fas fa-user-md"></i> {t('global.nav_advisor')}
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/videos`} className='mr-2'>
                            <i className="fas fa-video"></i> {t('global.nav_video')}
                        </Nav.Link>
                        {/* <Nav.Link as={Link} to={`/${lang || 'en'}/about-us`}>
                            {t('aboutUs.title')}
                        </Nav.Link> */}
                        {/* Only show the Sell Pets link (or login) on desktop */}

                        <Nav.Link as={Link} to={`/${lang || 'en'}/pet-calculator`} className='mr-2'>

                            <i className="fas fa-calculator"></i> {t('Adoption Calculator')}
                        </Nav.Link>







                    </Nav>
                    <Nav className="ms-auto">
                        {user ? (
                            <NavDropdown title={user.displayName || user.email} id="user-nav-dropdown">
                                <NavDropdown.Item onClick={handleSignOut}>{t('Logout')}</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            // Only show the login option on desktop
                            <Nav.Link as={Link} to={`/${lang || 'en'}/rehome-sell-pets`} className="d-none d-md-block">
                                {t('Login')}
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
