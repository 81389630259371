// src/components/LoginComponent.js
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    signInWithPopup,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    OAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider
} from 'firebase/auth';
import { auth } from '../firebase';

// Define logo URLs for each provider
const googleLogoUrl = 'https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg';
const facebookLogoUrl = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';
const appleLogoUrl = 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg';
const phoneLogoUrl = 'https://fonts.gstatic.com/s/i/materialicons/phone/v6/24px.svg';

const LoginComponent = () => {
    const { t, i18n } = useTranslation();
    // Sign in with Google
    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (err) {
            console.error('Google sign in error:', err);
        }
    };

    // Sign in with Facebook
    const signInWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (err) {
            console.error('Facebook sign in error:', err);
        }
    };

    // Sign in with Apple
    const signInWithApple = async () => {
        const provider = new OAuthProvider('apple.com');
        try {
            await signInWithPopup(auth, provider);
        } catch (err) {
            console.error('Apple sign in error:', err);
        }
    };

    // Sign in with Phone Number
    const signInWithPhone = async () => {
        // Set up reCAPTCHA (ensure that an element with this id exists)
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
        const phoneNumber = window.prompt('Please enter your phone number (with country code):');
        try {
            const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
            const code = window.prompt('Enter the OTP sent to your phone:');
            await confirmationResult.confirm(code);
        } catch (err) {
            console.error('Phone sign in error:', err);
        }
    };

    return (
        <div className="container mt-4" style={{ maxWidth: 500 }}>


            <div className="d-grid gap-3">
                <Button variant="outline-danger" onClick={signInWithGoogle}>
                    <img
                        src={googleLogoUrl}
                        alt="Google Logo"
                        style={{ width: 24, marginRight: 10 }}
                    />
                    Sign in with Google
                </Button>
                {/* <Button variant="outline-primary" onClick={signInWithFacebook}>
                    <img
                        src={facebookLogoUrl}
                        alt="Facebook Logo"
                        style={{ width: 24, marginRight: 10 }}
                    />
                    Sign in with Facebook
                </Button> */}
                <Button variant="outline-dark" onClick={signInWithApple}>
                    <img
                        src={appleLogoUrl}
                        alt="Apple Logo"
                        style={{ width: 24, marginRight: 10 }}
                    />
                    Sign in with Apple
                </Button>
                {/* <Button variant="outline-success" onClick={signInWithPhone}>
                    <img
                        src={phoneLogoUrl}
                        alt="Phone Logo"
                        style={{ width: 24, marginRight: 10 }}
                    />
                    Sign in with Phone
                </Button> */}
            </div>
            {/* Container for reCAPTCHA (used by phone auth) */}
            <div id="recaptcha-container" className="mt-3"></div>
        </div>
    );
};

export default LoginComponent;
