// ProfileCards.js
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';

function ProfileCards({ profiles, filters }) {
    // Filter the profiles based on the selected tag
    const filteredProfiles = profiles.filter((profile) => {
        if (filters.tag) {
            if (filters.tag === 'Playdate' && profile.isPlaydateSelected) return true;
            if (filters.tag === 'Breeding' && profile.isBreedingSelected) return true;
            if (filters.tag === 'PetSitter' && profile.isPetSitterSelected) return true;
            if (filters.tag === 'Advice' && profile.isAdviceSelected) return true;
            return false;
        }
        return true;
    });

    return (
        <Row>
            {filteredProfiles.map((profile) => (
                <Col key={profile.id} md={4} className="mb-4">
                    <Card className="h-100">
                        {/* Image container enforcing a 4:3 aspect ratio */}
                        <div
                            className="position-relative"
                            style={{
                                width: '100%',
                                paddingBottom: '75%', // 4:3 ratio (height = 75% of width)
                                overflow: 'hidden'
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={profile.image}
                                alt={profile.title}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            {/* Country flag in the top-right corner */}
                            {profile.country_code && (
                                <div
                                    className="position-absolute"
                                    style={{
                                        top: '8px',
                                        right: '8px',
                                        padding: '4px',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <ReactCountryFlag
                                        countryCode={profile.country_code}
                                        svg
                                        style={{ width: '1.5em', height: '1.5em' }}
                                        title={profile.country_code}
                                    />
                                </div>
                            )}
                            {/* Tags overlay on the bottom-right */}
                            <div
                                className="position-absolute"
                                style={{
                                    bottom: '8px',
                                    right: '4px',
                                    padding: '4px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    gap: '2px'
                                }}
                            >
                                {profile.isPlaydateSelected && (
                                    <span className="badge bg-info" style={{ fontSize: '0.6rem' }}>
                                        Playdate
                                    </span>
                                )}
                                {profile.isBreedingSelected && (
                                    <span className="badge bg-success" style={{ fontSize: '0.6rem' }}>
                                        Breeding
                                    </span>
                                )}
                                {profile.isPetSitterSelected && (
                                    <span className="badge bg-warning" style={{ fontSize: '0.6rem' }}>
                                        Pet Sitter
                                    </span>
                                )}
                                {profile.isAdviceSelected && (
                                    <span className="badge bg-primary" style={{ fontSize: '0.6rem' }}>
                                        Advice
                                    </span>
                                )}
                            </div>
                        </div>

                        {/* Card body */}
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>{profile.title}</Card.Title>
                            <Card.Text>{profile.intro}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
            {filteredProfiles.length === 0 && (
                <Col>
                    <p>No profiles match your filters.</p>
                </Col>
            )}
        </Row>
    );
}

export default ProfileCards;
