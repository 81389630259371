import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Row, Col, Card, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async'; // For managing document head
import slugify from 'slugify';
import './PetDetail.css';

// Helper: convert a string to a URL-friendly slug (unchanged)
const toSlug = (str) =>
    str
        .toLowerCase()
        .trim()
        .replace(/[\s]+/g, '-')
        .replace(/[^\w-]+/g, '');

// Helper: find original value from slug (unchanged)
const findOriginalValue = (slug, candidates = []) => {
    return candidates.find((val) => toSlug(val) === slug);
};

const FindPetsDetail = () => {
    const { countrySlug, regionSlug, citySlug } = useParams();
    const [adverts, setAdverts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [locationTree, setLocationTree] = useState(null);

    const { t } = useTranslation();
    const location = useLocation();

    // Fetch the location tree for slug-to-name conversion
    useEffect(() => {
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/locations')
            .then((res) => {
                if (!res.ok) throw new Error('Failed to load location data');
                return res.json();
            })
            .then((data) => setLocationTree(data))
            .catch((error) => {
                console.error('Error loading location tree:', error);
                setError('Failed to load location data. Please try again later.');
            });
    }, []);

    // Convert slugs to actual values (unchanged)
    let countryName, regionName, cityName;
    if (locationTree && countrySlug) {
        const countries = Object.keys(locationTree);
        countryName = findOriginalValue(countrySlug, countries);

        if (countryName && regionSlug) {
            const regions = Object.keys(locationTree[countryName]);
            regionName = findOriginalValue(regionSlug, regions);

            if (regionName && citySlug) {
                const cities = locationTree[countryName][regionName];
                cityName = findOriginalValue(citySlug, cities);
            }
        }
    }

    // Build query parameters for adverts API (unchanged)
    let queryParams = '';
    if (countryName) queryParams += `&countryName=${encodeURIComponent(countryName)}`;
    if (regionName) queryParams += `&region=${encodeURIComponent(regionName)}`;
    if (cityName) queryParams += `&city=${encodeURIComponent(cityName)}`;

    // Fetch adverts
    useEffect(() => {
        if (locationTree) {
            setLoading(true);
            fetch(`https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all?allAds=true${queryParams}`)
                .then((res) => {
                    if (!res.ok) throw new Error('Failed to fetch pet listings');
                    return res.json();
                })
                .then((data) => {
                    setAdverts(Array.isArray(data) ? data : []);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching adverts:', err);
                    setError('Failed to load pet listings. Please try again later.');
                    setLoading(false);
                });
        }
    }, [locationTree, queryParams]);

    // Location string for display and meta tags
    const locationString = [countryName || countrySlug, regionName || regionSlug, cityName || citySlug]
        .filter(Boolean)
        .join(' > ');

    // SEO title and meta description
    const pageTitle = `Pets for Adoption in ${locationString} | PetsHome`;
    const metaDescription = `Find pets for adoption, rescue, and rehoming in ${locationString}. Browse ${adverts.length} available pets including cats, dogs, and more.`;

    // Show a spinner while loading
    if (loading) {
        return (
            <>
                <Helmet>
                    <title>Loading Pet Listings | PetsHome</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading adverts...</span>
                    </Spinner>
                </div>
            </>
        );
    }

    // Show error message if there's an error
    if (error) {
        return (
            <>
                <Helmet>
                    <title>Error | PetsHome</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="container my-4">
                    <Alert variant="danger">{error}</Alert>
                </div>
            </>
        );
    }

    // Count pets by type for enhanced meta description
    const petTypeCount = adverts.reduce((acc, ad) => {
        const petType = ad.pet_type || 'Other';
        acc[petType] = (acc[petType] || 0) + 1;
        return acc;
    }, {});

    // Create a summary of available pet types for meta description
    const petTypeSummary = Object.entries(petTypeCount)
        .map(([type, count]) => `${count} ${type}${count !== 1 ? 's' : ''}`)
        .join(', ');

    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={`https://www.petshome.app${location.pathname}`} />

                {/* Open Graph Tags */}
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://www.petshome.app${location.pathname}`} />
                {adverts.length > 0 && adverts[0].main_image && (
                    <meta property="og:image" content={adverts[0].main_image} />
                )}

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />
            </Helmet>

            <div className="container my-4 FindPetsDetail">
                <header className="mb-4">
                    <h1 className="h1 text-dark">
                        Pets for Adoption in {locationString}
                    </h1>
                    {petTypeSummary && (
                        <p className="lead">Available now: {petTypeSummary}</p>
                    )}
                    <p className="tagline">Adopt, Rehome &amp; Rescue Your Perfect Companion</p>
                </header>

                {adverts.length === 0 ? (
                    <Alert variant="info">
                        <h2 className="h5">No pets currently available in this location</h2>
                        <p>Check back soon or expand your search to nearby areas.</p>
                    </Alert>
                ) : (
                    <>
                        <section aria-labelledby="pet-listings">
                            <h2 id="pet-listings" className="visually-hidden">Pet Listings</h2>
                            <Row xs={1} md={2} lg={3} className="g-4">
                                {adverts.map((ad) => {
                                    // Build detail URL
                                    const countryS = ad.countryName ? slugify(ad.countryName, { lower: true }) : countrySlug;
                                    const regionS = ad.region ? slugify(ad.region, { lower: true }) : regionSlug;
                                    const cityS = ad.city ? slugify(ad.city, { lower: true }) : citySlug;
                                    const adSlug = slugify(ad.title || 'no-title', { lower: true });
                                    const detailUrl = `/en/pet-classified-ads/${countryS}/${regionS}/${cityS}/${adSlug}/${ad.id}`;

                                    // Create descriptive alt text
                                    const altText = `${ad.pet_breed || 'Pet'} - ${ad.title} available for adoption in ${ad.city || cityName || ''}`;

                                    return (
                                        <Col key={ad.id}>
                                            <article className="h-100">
                                                <Card className="pet-card h-100 shadow-sm">
                                                    {ad.main_image && (
                                                        <div className="position-relative">
                                                            <Card.Img
                                                                variant="top"
                                                                src={ad.main_image}
                                                                alt={altText}
                                                                className="pet-image"
                                                                loading="lazy"
                                                                width="100%"
                                                                height="auto"
                                                            />
                                                            {ad.pet_type && (
                                                                <span className="position-absolute top-0 end-0 badge bg-primary m-2">
                                                                    {ad.pet_type}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                    <Card.Body>
                                                        <h3 className="h5 mb-2">
                                                            <Link
                                                                to={detailUrl}
                                                                className="text-decoration-none text-primary"
                                                            >
                                                                {ad.title}
                                                            </Link>
                                                        </h3>
                                                        <p className="mb-1">
                                                            <strong>Breed:</strong> {ad.pet_breed || 'Unknown'}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Location:</strong> {ad.city || cityName || ''}, {ad.region || regionName || ''}
                                                        </p>
                                                        <p className="mb-2 pet-description">
                                                            {ad.description
                                                                ? ad.description.substring(0, 80) + '...'
                                                                : 'No description available for this pet.'}
                                                        </p>
                                                        <Link
                                                            to={detailUrl}
                                                            className="btn btn-sm btn-outline-primary"
                                                            aria-label={`View details for ${ad.title}`}
                                                        >
                                                            View Details
                                                        </Link>
                                                    </Card.Body>
                                                </Card>
                                            </article>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </section>

                        {/* Add Schema.org JSON-LD structured data */}
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "ItemList",
                                "itemListElement": adverts.map((ad, index) => ({
                                    "@type": "ListItem",
                                    "position": index + 1,
                                    "item": {
                                        "@type": "Product",
                                        "name": ad.title,
                                        "description": ad.description,
                                        "image": ad.main_image,
                                        "offers": {
                                            "@type": "Offer",
                                            "availability": "https://schema.org/InStock",
                                            "price": ad.price || "0",
                                            "priceCurrency": ad.currency || "EUR"
                                        }
                                    }
                                }))
                            })}
                        </script>
                    </>
                )}
            </div>
        </>
    );
};

export default FindPetsDetail;