// PetCalculator.jsx
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Button, ProgressBar } from 'react-bootstrap';
import { VictoryPie } from 'victory';

import BreedInfo from './BreedInfo';
import './PetCalculator.css'; // optional custom CSS
import { t } from 'i18next';

// 10 popular pets in the US
// Each pet has:
//  - suitability: which user answers are acceptable
//  - costs: adoption, monthly, annual
//  - care: exercise, grooming, training
const petProfiles = {
  dog: {
    name: 'Dog',
    suitability: {
      living: ['house', 'rural'],
      activity: ['moderate', 'high'],
      budget: ['medium_budget', 'high_budget'],
      experience: ['experienced', 'any_experience'],
    },
    costs: {
      adoption: 300,
      monthly: 150,
      annual: 1800,
    },
    care: {
      exerciseNeeds: 'Daily walks & playtime (30-60 mins)',
      grooming: 'Moderate (brushing 1-2x per week)',
      training: 'Basic obedience recommended',
    },
  },
  cat: {
    name: 'Cat',
    suitability: {
      living: ['apartment', 'house'],
      activity: ['low', 'moderate'],
      budget: ['low_budget', 'medium_budget'],
      experience: ['any_experience', 'experienced'],
    },
    costs: {
      adoption: 200,
      monthly: 100,
      annual: 1200,
    },
    care: {
      exerciseNeeds: 'Indoor play sessions with toys',
      grooming: 'Minimal (1x per week)',
      training: 'Litter training is typical',
    },
  },
  fish: {
    name: 'Fish',
    suitability: {
      living: ['apartment', 'house', 'rural'],
      activity: ['low', 'moderate', 'high'],
      budget: ['low_budget', 'medium_budget', 'high_budget'],
      experience: ['no_experience', 'any_experience', 'experienced'],
    },
    costs: {
      adoption: 20,     // Basic setup cost
      monthly: 10,
      annual: 120,
    },
    care: {
      exerciseNeeds: 'None beyond tank space & water quality',
      grooming: 'Tank cleaning & water changes',
      training: 'Consistent feeding schedule, no typical "training"',
    },
  },
  bird: {
    name: 'Bird',
    suitability: {
      living: ['apartment', 'house'],
      activity: ['low', 'moderate'],
      budget: ['low_budget', 'medium_budget'],
      experience: ['any_experience', 'experienced'],
    },
    costs: {
      adoption: 50,
      monthly: 50,
      annual: 600,
    },
    care: {
      exerciseNeeds: 'Daily out-of-cage time (supervised)',
      grooming: 'Occasional nail/wing trims',
      training: 'Basic taming & social interaction',
    },
  },
  rabbit: {
    name: 'Rabbit',
    suitability: {
      living: ['apartment', 'house'],
      activity: ['low', 'moderate'],
      budget: ['low_budget', 'medium_budget'],
      experience: ['any_experience', 'experienced'],
    },
    costs: {
      adoption: 50,
      monthly: 50,
      annual: 600,
    },
    care: {
      exerciseNeeds: 'Daily time outside the cage for exercise',
      grooming: 'Frequent brushing for some breeds',
      training: 'Gentle handling, can learn litter training',
    },
  },
  hamster: {
    name: 'Hamster',
    suitability: {
      living: ['apartment', 'house', 'rural'],
      activity: ['low', 'moderate'],
      budget: ['low_budget', 'medium_budget'],
      experience: ['no_experience', 'any_experience'],
    },
    costs: {
      adoption: 15,
      monthly: 25,
      annual: 300,
    },
    care: {
      exerciseNeeds: 'Wheel for running, occasional outside-cage time',
      grooming: 'Minimal—mostly self-grooming',
      training: 'Basic handling & taming',
    },
  },
  guineaPig: {
    name: 'Guinea Pig',
    suitability: {
      living: ['apartment', 'house'],
      activity: ['low', 'moderate'],
      budget: ['low_budget', 'medium_budget'],
      experience: ['no_experience', 'any_experience', 'experienced'],
    },
    costs: {
      adoption: 30,
      monthly: 35,
      annual: 420,
    },
    care: {
      exerciseNeeds: 'Daily floor time, large cage',
      grooming: 'Weekly brushing (long-haired breeds)',
      training: 'Gentle handling; can learn small tricks',
    },
  },
  turtle: {
    name: 'Turtle',
    suitability: {
      living: ['apartment', 'house', 'rural'],
      activity: ['low'],
      budget: ['medium_budget', 'high_budget'],
      experience: ['any_experience', 'experienced'],
    },
    costs: {
      adoption: 30,
      monthly: 20,
      annual: 240,
    },
    care: {
      exerciseNeeds: 'Proper aquarium/terrarium with basking area',
      grooming: 'Filtration, shell checks, water changes',
      training: 'Not typical training; consistent care needed',
    },
  },
  snake: {
    name: 'Snake',
    suitability: {
      living: ['apartment', 'house', 'rural'],
      activity: ['low', 'moderate'],
      budget: ['medium_budget', 'high_budget'],
      experience: ['experienced'],
    },
    costs: {
      adoption: 50,
      monthly: 30,
      annual: 360,
    },
    care: {
      exerciseNeeds: 'Enclosure with room to move & hide',
      grooming: 'Shed management & humidity control',
      training: 'Handle with care; knowledge of species is key',
    },
  },
  ferret: {
    name: 'Ferret',
    suitability: {
      living: ['apartment', 'house'],
      activity: ['moderate', 'high'],
      budget: ['medium_budget', 'high_budget'],
      experience: ['any_experience', 'experienced'],
    },
    costs: {
      adoption: 100,
      monthly: 70,
      annual: 840,
    },
    care: {
      exerciseNeeds: 'Daily playtime outside cage; very active',
      grooming: 'Regular baths & ear cleaning',
      training: 'Can be litter-trained, need consistent handling',
    },
  },
};

// 4 main questions
const questions = [
  {
    id: 'living',
    question: 'What is your current living situation?',
    options: [
      { value: 'apartment', label: 'Apartment' },
      { value: 'house', label: 'House with Yard' },
      { value: 'rural', label: 'Rural Area' },
    ],
  },
  {
    id: 'activity',
    question: 'How active is your lifestyle?',
    options: [
      { value: 'low', label: 'Mostly Home / Sedentary' },
      { value: 'moderate', label: 'Moderately Active' },
      { value: 'high', label: 'Very Active / Outdoorsy' },
    ],
  },
  {
    id: 'budget',
    question: "What's your approximate monthly pet budget?",
    options: [
      { value: 'low_budget', label: 'Low Budget' },
      { value: 'medium_budget', label: 'Medium Budget' },
      { value: 'high_budget', label: 'High Budget' },
    ],
  },
  {
    id: 'experience',
    question: 'How experienced are you with pets?',
    options: [
      { value: 'no_experience', label: 'No Experience' },
      { value: 'any_experience', label: 'Some Experience' },
      { value: 'experienced', label: 'Very Experienced' },
    ],
  },
];

const PetCalculator = () => {
  const [step, setStep] = useState(1); // track question step
  const [answers, setAnswers] = useState({});
  const [recommendation, setRecommendation] = useState(null);

  // Handle each question's answer
  const handleAnswer = (questionId, answerValue) => {
    setAnswers((prev) => ({ ...prev, [questionId]: answerValue }));
    if (step < questions.length) {
      setStep(step + 1);
    } else {
      // last question answered, do the calculation
      calculateRecommendation();
    }
  };

  // Determine best matching pet
  const calculateRecommendation = () => {
    let bestMatch = null;
    let bestScore = -1;

    // Score each pet based on how many of the user's answers match its suitability
    Object.entries(petProfiles).forEach(([key, profile]) => {
      let score = 0;
      Object.entries(profile.suitability).forEach(([qId, validAnswers]) => {
        if (validAnswers.includes(answers[qId])) {
          score++;
        }
      });
      if (score > bestScore) {
        bestScore = score;
        bestMatch = key;
      }
    });

    // set final recommended pet
    if (bestMatch) {
      setRecommendation(petProfiles[bestMatch]);
    }
  };

  // Reset quiz
  const resetCalculator = () => {
    setStep(1);
    setAnswers({});
    setRecommendation(null);
  };

  return (


    <Container className="pet-calculator mt-4">
      {/* SEO HEADS with react-helmet */}
      <Helmet>
        <title>Pet Adoption Calculator: Find the Perfect Pet for You</title>
        <meta
          name="description"
          content="Discover which pet (dog, cat, fish, bird, and more) best suits your lifestyle, budget, and experience level. Explore estimated costs and care instructions to adopt responsibly."
        />
        <meta
          name="keywords"
          content="pet calculator, pet adoption, dog, cat, fish, bird, rabbit, hamster, best pets, find a pet"
        />
      </Helmet>
      <Row>


        <h1 className="text-center mb-3">{t('Pet Adoption Calculator')}</h1>
        <p className="text-center mb-4">
          {t('Answer a few questions about your living situation, budget, and experience to discover which pet is right for you.')}
        </p>
        <Col md={6} className='' >

          {/* If we haven't reached the recommendation */}
          {recommendation === null && step <= questions.length && (
            <>
              <ProgressBar
                now={(step / questions.length) * 100}
                label={`Step ${step} of ${questions.length}`}
                className="mb-4"
              />
              <Card className="p-4 shadow-sm">
                <Card.Body>
                  <h2 className="fs-5 mb-3">{questions[step - 1].question}</h2>
                  <div className="d-flex flex-wrap gap-2 mt-3">
                    {questions[step - 1].options.map((option) => (
                      <Button
                        key={option.value}
                        variant="outline-primary"
                        onClick={() => handleAnswer(questions[step - 1].id, option.value)}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </>
          )}

          {/* Show recommendation once calculated */}
          {recommendation !== null && (
            <div className="recommendation-results mt-4">
              <h3 className="text-center mb-4">Your Recommended Pet</h3>
              <Card className="shadow-sm">
                <Card.Body>
                  <h4 className="mb-3">We Suggest a {recommendation.name}!</h4>

                  <Row>
                    <Col xs={12} md={6}>
                      <h5>Estimated Costs</h5>
                      <ul className="list-unstyled">
                        <li><strong>Adoption Fee:</strong> ${recommendation.costs.adoption}</li>
                        <li><strong>Monthly Care:</strong> ~${recommendation.costs.monthly}</li>
                        <li><strong>Annual Care:</strong> ~${recommendation.costs.annual}</li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <div style={{ maxWidth: '280px', margin: '0 auto' }}>
                        <VictoryPie
                          data={[
                            { x: 'Adoption', y: recommendation.costs.adoption },
                            { x: 'Monthly', y: recommendation.costs.monthly },
                            { x: 'Annual', y: recommendation.costs.annual },
                          ]}
                          colorScale={['#007bff', '#28a745', '#ffc107']}
                          innerRadius={50}
                          labels={({ datum }) => `${datum.x}\n$${datum.y}`}
                          style={{ labels: { fontSize: 10 } }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <hr className="my-4" />

                  <h4>Care Requirements</h4>
                  <ul>
                    <li><strong>Exercise Needs:</strong> {recommendation.care.exerciseNeeds}</li>
                    <li><strong>Grooming:</strong> {recommendation.care.grooming}</li>
                    <li><strong>Training:</strong> {recommendation.care.training}</li>
                  </ul>

                  <div className="d-flex flex-wrap gap-3 mt-4">
                    {/* <Button variant="success" href="/adoption-centers">
                      Find Local Adoption Centers
                    </Button> */}
                    <Button variant="secondary" onClick={resetCalculator}>
                      Try Again
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>



          )}
        </Col>

        <Col md={6}>



          {recommendation !== null && (
            <>
              {/* Existing recommendation content */}

              <div className="additional-resources mt-4">


                <div className="additional-resources mt-4">
                  {/* <h3 className="text-center mb-4">Learn More About {recommendation.name}s</h3> */}

                  <BreedInfo petType={recommendation.name} />

                  <Card className="mt-4">
                    <Card.Body>
                      <h4>Additional Resources</h4>
                      <ul>
                        <li>
                          <a href={`https://www.akc.org/dog-breeds/`} target="_blank" rel="noopener noreferrer">
                            breed standards
                          </a>
                        </li>
                        <li>
                          <a href="https://www.aspca.org/pet-care" target="_blank" rel="noopener noreferrer">
                            Pet Care Guidelines
                          </a>
                        </li>
                        <li>
                          <a href="https://www.petfinder.com/" target="_blank" rel="noopener noreferrer">
                            Find Adoptable Pets Near You
                          </a>
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>

                  <Card className="mt-4">
                    <Card.Body>
                      <h4>Care Tips</h4>
                      <ul>
                        <li>Regular veterinary check-ups are essential</li>
                        <li>Maintain a consistent feeding schedule</li>
                        <li>Provide appropriate shelter and living conditions</li>
                        <li>Ensure proper socialization and exercise</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </>
          )}


        </Col>
      </Row>

    </Container>
  );
};

export default PetCalculator;
