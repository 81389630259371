// SocialProfileForm.js
import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

function SocialProfileForm({ onSubmit }) {
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [image, setImage] = useState(null);
    const [isPlaydate, setIsPlaydate] = useState(false);
    const [isBreeding, setIsBreeding] = useState(false);
    const [isPetSitter, setIsPetSitter] = useState(false);
    const [isAdvice, setIsAdvice] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [error, setError] = useState('');

    // Handle image file selection (you could add cropping here)
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title.trim() || !intro.trim()) {
            setError('Title and introduction are required.');
            return;
        }
        if (!image) {
            setError('Please upload a profile image.');
            return;
        }

        // Use FormData to allow image upload (if your API expects multipart/form-data)
        const formData = new FormData();
        formData.append('title', title);
        formData.append('intro', intro);
        formData.append('image', image);
        formData.append('isPlaydateSelected', isPlaydate);
        formData.append('isBreedingSelected', isBreeding);
        formData.append('isPetSitterSelected', isPetSitter);
        formData.append('isAdviceSelected', isAdvice);

        // (Optional: append additional fields like country_code and location)

        onSubmit(formData);

        // Clear the form after submission
        setTitle('');
        setIntro('');
        setImage(null);
        setPreviewUrl('');
        setIsPlaydate(false);
        setIsBreeding(false);
        setIsPetSitter(false);
        setIsAdvice(false);
        setError('');
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Title / Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your title / Name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength="50"
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Introduction</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Tell us a little about yourself..."
                    value={intro}
                    onChange={(e) => setIntro(e.target.value)}
                    maxLength="200"
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Profile Image</Form.Label>
                <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                />
                {previewUrl && (
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className="img-thumbnail mt-2"
                        style={{ maxHeight: '200px' }}
                    />
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Playdate"
                    checked={isPlaydate}
                    onChange={() => setIsPlaydate(!isPlaydate)}
                />
                <Form.Check
                    type="checkbox"
                    label="Breeding"
                    checked={isBreeding}
                    onChange={() => setIsBreeding(!isBreeding)}
                />
                <Form.Check
                    type="checkbox"
                    label="Pet Sitter"
                    checked={isPetSitter}
                    onChange={() => setIsPetSitter(!isPetSitter)}
                />
                <Form.Check
                    type="checkbox"
                    label="Advice"
                    checked={isAdvice}
                    onChange={() => setIsAdvice(!isAdvice)}
                />
            </Form.Group>

            <Button type="submit">Submit Profile</Button>
        </Form>
    );
}

export default SocialProfileForm;
