import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import './FindPets.css'; // Import CSS for responsive layout
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { Helmet } from 'react-helmet-async';
import LocationLinks from './LocationLinks';

function FindPets() {
    const [petAds, setPetAds] = useState([]);
    const { lang } = useParams();
    const { t } = useTranslation();

    // Fetch the pet ads on component mount
    useEffect(() => {
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all')
            .then((response) => response.json())
            .then((data) => setPetAds(data))
            .catch((error) => console.error('Error fetching pet listings:', error));
    }, []);

    // Generate each advert card
    const generateAdvertCard = (ad, key) => {
        const {
            id,
            title,
            pet_breed,
            main_image,
            author_country_code,
            asking_price,
            description,
            countryName,
            region,
            city,
        } = ad;

        const flagUrl = `https://flagcdn.com/24x18/${author_country_code.toLowerCase()}.png`;
        const petBreed = pet_breed || 'Mixed Breed';

        // Build the pet slug based on pet breed and title
        const slug =
            `${pet_breed || 'pet'}-${title}`
                .toLowerCase()
                .normalize('NFD') // Normalize accents
                .replace(/[\u0300-\u036f]/g, '') // Remove accents
                .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                .replace(/^-+|-+$/g, ''); // Trim hyphens

        // Build a location string (for display) if any location data is provided
        const locationString =
            countryName || region || city
                ? `${city ? city + ', ' : ''}${region ? region + ', ' : ''}${countryName ? countryName : ''}`.replace(
                    /,\s*$/,
                    ''
                )
                : '';

        // Build the ad title. If location info exists, include it.
        const adTitle = locationString ? `${title} - ${locationString}` : title;

        // Build the detail page URL.
        // If location details exist, include them in the URL; otherwise use the fallback.
        const detailUrl =
            countryName && region && city
                ? `/${lang}/classified/${slugify(countryName, { lower: true })}/${slugify(region, { lower: true })}/${slugify(city, { lower: true })}/${slug}/${id}`
                : `/${lang}/${slug}/${id}`;

        return (
            <Card key={key} className="pet-card">
                <div style={{ position: 'relative' }}>
                    {main_image && (
                        <Card.Img
                            variant="top"
                            src={main_image}
                            style={{ objectFit: 'cover', height: '200px' }}
                            alt={`Image of ${petBreed}`}
                        />
                    )}
                    <img
                        src={flagUrl}
                        alt={`Flag of ${author_country_code}`}
                        style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            width: '24px',
                            height: '18px',
                            borderRadius: '3px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                        }}
                    />
                </div>
                <Card.Body>
                    {/* Clickable Title */}
                    <Link to={detailUrl} className="card-title-link text-decoration-none">
                        <Card.Title style={{ fontSize: '16px', color: '#007bff' }}>
                            {adTitle}
                        </Card.Title>
                    </Link>
                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>{petBreed}</strong>
                    </Card.Text>
                    <Card.Text
                        style={{
                            fontSize: '12px',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {description}
                    </Card.Text>
                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>Price / Rehome Fee:</strong> ${asking_price}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <Helmet>
                <title>{t('find.meta_title')}</title>
                <meta name="description" content={t('find.meta_description')} />
                <meta name="keywords" content={t('find.meta_keywords')} />
            </Helmet>

            <div className="FindPets">
                <Container className="p-4">
                    <Row>




                        <Col md={4} className="p-4">

                            <h2 className="my-4">{t('find.h1')}</h2>
                            <p className="text-muted">{t('find.description')}</p>
                            <div className="text-center  d-none d-md-block">

                                <Link
                                    className="btn btn-success text-dark btn-lg my-2"
                                    aria-label="Post Your Free Pet Ad Now"
                                    to={`/${lang}/rehome-sell-pets`}
                                    style={{ backgroundColor: '#00FFFF', color: '#38C1D8' }}
                                >
                                    <i className="fas fa-plus"></i> {t("Post Your Free Pet Ad Now")}
                                </Link>
                            </div>

                            <h5 className="text-center font-weight-bold mt-4">{t('global.h5_dwonload')}</h5>
                            <div className="d-flex justify-content-center">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.app.petshome"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mr-3"
                                    title="Download Pets Home App from Google Play Store"
                                >
                                    <img
                                        style={{ width: '180px', height: 'auto' }}
                                        src="/google-play-badge.svg"
                                        alt="Download Pets Home App on Google Play"
                                    />
                                </a>
                                <a
                                    href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Download Pets Home App from Apple App Store"
                                >
                                    <img
                                        style={{ width: '180px', height: 'auto' }}
                                        src="/app-store-badge.svg"
                                        alt="Download Pets Home App on App Store"
                                    />
                                </a>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <img
                                    style={{ width: '130px', height: 'auto' }}
                                    src="/android-adobe-express-qr-code.png"
                                    alt="Android QR Code"
                                    className="mr-3"
                                />
                                <img
                                    style={{ width: '130px', height: 'auto' }}
                                    src="/ios-express-qr-code.png"
                                    alt="iOS QR Code"
                                />
                            </div>
                            <p className="text-center mt-3 d-none d-md-block">
                                {t('Scan the QR codes below for quick access:')}
                            </p>
                        </Col>

                        <Col md={8} className="p-4">
                            <div className="pet-listing">
                                {petAds.length > 0 ? (
                                    petAds.map((ad, index) => generateAdvertCard(ad, index))
                                ) : (
                                    <p>No pet listings available at the moment.</p>
                                )}
                            </div>

                        </Col>
                    </Row>
                </Container>
                {/* <Container  >
                    <LocationLinks></LocationLinks>
                </Container> */}

            </div>
        </>
    );
}

export default FindPets;
