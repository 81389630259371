import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import './home.css'; // Add your custom styles here



function Home() {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language; // or however you manage languages
  const [faqs, setFaqs] = useState([]);
  // Map of languages to corresponding FAQ file
  const languageMap = {
    en: '/data/faqs_en.json',  // Default English FAQs
    de: '/data/faqs_de.json',
    ar: '/data/faqs_ar.json',
    fr: '/data/faqs_fr.json',
    es: '/data/faqs_es.json',
    it: '/data/faqs_it.json',
    pt: '/data/faqs_pt.json',
    da: '/data/faqs_da.json',
    sv: '/data/faqs_sv.json',
    pl: '/data/faqs_pl.json',
    no: '/data/faqs_no.json',
    zh: '/data/faqs_zh.json'
  };

  useEffect(() => {
    // Determine the current language and load the appropriate FAQ file
    const currentLanguage = i18n.language || 'en';  // Default to English if language is not set
    const faqFile = languageMap[currentLanguage] || languageMap['en']; // Fallback to English if no match

    // Fetch the relevant FAQ data based on the current language
    fetch(faqFile)
      .then(response => response.json())
      .then(data => setFaqs(data))
      .catch(error => console.error('Error fetching FAQs:', error));
  }, [i18n.language]); // Re-run effect when the language changes



  return (
    <>

      <Helmet>
        <link rel="preload" as="image" href="assets/web/rehome-adopt-buy-sell-pets.png" />
        <link rel="preload" as="image" href="assets/rehome-adopt-buy-sell-pets.png" />
        <title>{t('home.meta_title')}</title>
        <meta name="description" content={t('home.meta_description')} />
        <meta name="keywords" content={t('home.meta_keywords')} />
      </Helmet>
      <div className="text-dark" style={{ minHeight: '100vh' }}>
        {/* Breadcrumb Navigation */}
        {/* <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Rehome / Sell Pets</li>
        </ol>
      </nav> */}

        <section className="text-center">
          <div className="jumbotron text-dark" style={{ paddingBottom: '0', backgroundColor: 'white' }}>
            <Container>
              <Row>
                <Col md={12} className="p-2 text-center">
                  <h1 className="display-8 text-dark">
                    {t('home.h1')}
                  </h1>
                  <p className="lead">
                    {t('home.subheading')}
                  </p>
                  <div className="text-center  d-none d-md-block">

                    <Link
                      className="btn btn-success text-dark btn-lg my-2"
                      aria-label="Post Your Free Pet Ad Now"
                      to={`/${currentLanguage}/rehome-sell-pets`}
                      style={{ backgroundColor: '#00FFFF', color: '#f8f9fa' }}
                    >
                      <i className="fas fa-plus"></i> {t('Post Your Free Pet Ad Now')}
                    </Link>
                  </div>


                  <div className="image-container">
                    {/* Desktop/Tablet Image */}
                    <img

                      style={{ maxWidth: '700px', height: 'auto' }}
                      className="img-fluid rounded mb-0 desktop-image"
                      src="assets/web/rehome-adopt-buy-sell-pets.png"
                      alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                    />

                    {/* Mobile Images */}
                    <img

                      style={{ maxWidth: '350px', height: 'auto' }}
                      className="img-fluid rounded mb-4 mobile-image"
                      src="assets/rehome-adopt-buy-sell-pets.png"
                      alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                    />
                    {/* <img
                      style={{ maxWidth: '300px', height: 'auto' }}
                      className="img-fluid rounded mb-4 mobile-image"
                      src="assets/web/freepetads.jpg"
                      alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                    /> */}
                  </div>


                  <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                  <div className="d-flex justify-content-center">
                    <a href="https://play.google.com/store/apps/details?id=com.app.petshome" aria-label="Download Pets Home App from Google Play Store" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                      <img loading="lazy" style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                    </a>
                    <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" aria-label="Download Pets Home App on App Store" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                      <img loading="lazy" style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                    </a>
                  </div>
                  <small>  {t('global.rated')} </small>
                  <div>
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                    <FontAwesomeIcon icon={faStar} color="gold" />
                  </div>


                </Col>

                <p className="text-center  d-none d-md-block">{t('Scan the QR codes below for quick access:')}</p>
                <div className="text-center d-none d-md-block">
                  Android :
                  <img loading="lazy" style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                  Apple :
                  <img loading="lazy" style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                </div>

              </Row>
            </Container>
          </div>
        </section >



        <Container  >
          <Row>


            <Col md={6} className="text-center  d-md-block">
              <h3>{t('home.h3_breed')}</h3>
              <p className="lead">
                {t('home.h3_breed_p')}
              </p>

              <img
                loading="lazy"
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="assets/web/breedscanner.jpg"
                alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
              />

            </Col>


            <Col md={6} className="text-center  d-md-block">
              <h3>{t('advisor.h1')}</h3>
              <p className="lead">
                {t('advisor.description')}
              </p>
              <img
                loading="lazy"
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="assets/web/pet-advisor.png"
                alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
              />

            </Col>
            <Col md={6} className="text-center d-md-block">

              <h3>{t('home.h3_video')}</h3>
              <p className="lead">
                {t('home.h3_video_p')}
              </p>

              <img
                loading="lazy"
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="assets/web/pet-videos.jpg"
                alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
              />
            </Col>
            <Col md={6} className="text-center d-md-block">
              <h3>{t('home.h3_social')}</h3>
              <p className="lead">
                {t('home.h3_social_p')}
              </p>
              <img
                loading="lazy"
                style={{ maxWidth: '300px', height: 'auto' }}
                className="img-fluid rounded mb-4 center"
                src="assets/web/pet-connect.png"
                alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
              />

            </Col>
          </Row>
        </Container>

        <Container>
          <Col className="text-center  d-md-block">
            <h3>{t('home.h2_buy')} </h3>

            <p className="lead">
              {t('home.h2_buy_p')}
            </p>


            <h3>{t('home.h3_sell')} </h3>

            <p className="lead">
              {t('home.h3_sell_p')}
            </p>



          </Col>
        </Container>


        <div className="image-container">

          <img
            loading="lazy"
            style={{ maxWidth: '300px', height: 'auto' }}
            className="img-fluid rounded mb-4 mobile-image"
            src="assets/web/freepetads.jpg"
            alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
          />
        </div>




        {/* Social Sharing Buttons */}
        <div className="social-sharing text-center my-4">
          <p>{t('global.share_p')}</p>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" className="mx-2" />
          </a>
          <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Sell%20Your%20Pet%20Online%20for%20Free%20with%20Pets%20Home%20App" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="2x" className="mx-2" />
          </a>
          <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" className="mx-2" />
          </a>
          <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPinterest} size="2x" className="mx-2" />
          </a>
          <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" className="mx-2" />
          </a>
        </div>


      </div >

      <Container className='text-center mb-4'>
        <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
        <div className="d-flex justify-content-center">
          <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
            <img loading="lazy" style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
          </a>
          <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
            <img loading="lazy" style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
          </a>
        </div>
        <small>  {t('global.rated')} </small>
        <div>
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
          <FontAwesomeIcon icon={faStar} color="gold" />
        </div>


        <p className="text-center  d-none d-md-block">{t('Scan the QR codes below for quick access:')}</p>
        <div className="text-center d-none d-md-block">
          Android :
          <img loading="lazy" style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
          Apple :
          <img loading="lazy" style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
        </div>
      </Container>


      {/* FAQ Section */}
      {/* {i18n.language === 'en' ? (
        // If language is English
        <Container>
          <FAQ />
        </Container>
      ) : ( */}

      <Container>
        <h2>{t('global.h5_faq')}</h2>
        <p>{t('global.h5_faq_p')}</p>
        <Row>
          {faqs.map((faq, index) => (
            <Col key={index} md={12} className="mb-4">
              <Card>
                <Card.Header as="h5">{faq.question}</Card.Header>
                <Card.Body>
                  <Card.Text>{faq.answer}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {/* )} */}

      {/* <Container  >
        <LocationLinks></LocationLinks>
      </Container> */}
    </>
  );
}

export default Home;
