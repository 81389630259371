// AdvertCarousel.js
import React from 'react';
import { Carousel, Badge } from 'react-bootstrap';

const AdvertCarousel = ({ advert }) => {
    // Build an array of images from advert.images
    const images = [];
    if (advert.images) {
        if (advert.images.main_image) images.push(advert.images.main_image);
        if (advert.images.image_1) images.push(advert.images.image_1);
        if (advert.images.image_2) images.push(advert.images.image_2);
    }
    // If no images available, use the placeholder image
    if (images.length === 0) {
        images.push('/assets/placeholder-image.png');
    }

    // Determine the status label and variant
    let statusLabel = '';
    let statusVariant = '';
    if (advert.advertStatus === 0) {
        statusLabel = 'Under Review';
        statusVariant = 'warning';
    } else if (advert.advertStatus === 1) {
        statusLabel = '✅ Published';
        statusVariant = 'success';
    } else {
        statusLabel = 'Unknown';
        statusVariant = 'secondary';
    }

    return (
        <div style={{ position: 'relative' }}>
            {/* Overlay the status badge */}
            <Badge
                bg={statusVariant}
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    zIndex: 2,
                    fontSize: '1rem',
                    padding: '0.5rem'
                }}
            >
                {statusLabel}
            </Badge>

            {/* Carousel with auto-scroll disabled and fixed image dimensions */}
            <Carousel interval={null} indicators={images.length > 1} controls={images.length > 1}>
                {images.map((img, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={img}
                            alt={`Slide ${index + 1}`}
                            style={{ height: '300px', objectFit: 'cover' }}  // fixed height and consistent image display
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default AdvertCarousel;
