// LocationLinks.jsx
import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LocationLinks = () => {
    const [locationTree, setLocationTree] = useState({});
    const [loading, setLoading] = useState(true);

    // Fetch the locations tree from your Cloud Function
    useEffect(() => {
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/locations')
            .then((response) => response.json())
            .then((data) => {
                setLocationTree(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching locations:', error);
                setLoading(false);
            });
    }, []);

    // Helper: convert a string to a URL-friendly slug
    const toSlug = (str) =>
        str
            .toLowerCase()
            .trim()
            .replace(/[\s]+/g, '-') // Replace spaces with hyphens
            .replace(/[^\w-]+/g, ''); // Remove all non-word characters

    if (loading) return <p>Loading locations...</p>;

    return (
        <Container className="my-4">
            <h5 className="mb-4">Find Pets by Location</h5>
            <Row xs={1} md={2} lg={1} className="g-4">
                {Object.entries(locationTree).map(([country, regions]) => {
                    const countrySlug = toSlug(country);
                    const countryUrl = `/en/pet-classified-ads/${countrySlug}`;

                    return (
                        <Col key={country}>
                            <Card>
                                <Card.Header className="fw-bold">
                                    <Link to={countryUrl}>{country}</Link>
                                </Card.Header>

                                <Card.Body>
                                    {Object.entries(regions).map(([region, cities]) => {
                                        const regionSlug = toSlug(region);
                                        const regionUrl = `/en/pet-classified-ads/${countrySlug}/${regionSlug}`;

                                        return (
                                            <div key={region} className="mb-3">
                                                <h6 className="mb-2">
                                                    <Link to={regionUrl}>{region}</Link>
                                                </h6>
                                                {cities.map((city) => {
                                                    const citySlug = toSlug(city);
                                                    const cityUrl = `/en/pet-classified-ads/${countrySlug}/${regionSlug}/${citySlug}`;
                                                    return (
                                                        <span key={city} className="me-3">
                                                            <Link to={cityUrl}>{city}</Link>
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

export default LocationLinks;
