// ProfileFilters.js
import React from 'react';
import { FaPaw, FaHeartbeat, FaUserShield, FaCommentDots } from 'react-icons/fa';

// Define the filter options with labels and icons
const filterOptions = [
    { value: '', label: 'All', icon: null },
    { value: 'Playdate', label: 'Playdate', icon: <FaPaw /> },
    { value: 'Breeding', label: 'Breeding', icon: <FaHeartbeat /> },
    { value: 'PetSitter', label: 'Pet Sitter', icon: <FaUserShield /> },
    { value: 'Advice', label: 'Advice', icon: <FaCommentDots /> },
];

function ProfileFilters({ filters, setFilters }) {
    const handleFilterChange = (selectedValue) => {
        // Toggle the filter off if it’s already selected
        setFilters({ ...filters, tag: filters.tag === selectedValue ? '' : selectedValue });
    };

    return (
        <div className="d-flex align-items-center mb-3">
            <span className="me-3 fw-bold">Filter by Category:</span>
            {filterOptions.map((option) => (
                <div
                    key={option.value || 'all'}
                    className={`d-flex align-items-center me-3 p-2 border rounded clickable ${filters.tag === option.value ? 'bg-primary text-white' : 'bg-light'
                        }`}
                    onClick={() => handleFilterChange(option.value)}
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                    {option.icon && <span className="me-1">{option.icon}</span>}
                    <span>{option.label}</span>
                </div>
            ))}
        </div>
    );
}

export default ProfileFilters;
