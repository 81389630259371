import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navigation from './components/Navbar';
import FindPets from './pages/FindPets';
import SellPetsPage from './pages/CreateAdvert';
import PetDetail from './pages/PetDetail';
import Home from './pages/Home';
import BreedDetector from './pages/BreedDetector';
import PetAdvisor from './pages/PetAdvisor';
import SocialPage from './pages/SocialPage';
import VideoPage from './pages/videos';
import Termsofuse from './pages/Termsofuse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CancelSubscription from './pages/cancelSubscription';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/minty/bootstrap.min.css';
import './i18n';
import './App.css';

import ReactGA from 'react-ga4';
import LetsPartner from './pages/LetsPartner';
import Affiliate from './pages/Affiliate';
import AboutUs from './pages/AboutUs';
import CreateAdvert from './pages/CreateAdvert';
import FindPetsDetail from './pages/FindPetsDetail';
import LocationLinks from './pages/LocationLinks';
import PetCalculator from './pages/PetCalculator';

ReactGA.initialize('TBHT1X4QEV');

function App() {
  const { i18n } = useTranslation();
  return (
    <Routes>
      {/* Public (language-agnostic) routes */}
      <Route path="/terms-of-use" element={<Termsofuse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* MainLayout handles all /:lang/ routes */}
      <Route path="/:lang/*" element={<MainLayout />} />

      {/* Redirect root to default language */}
      <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />

      {/* Catch-all: redirect unknown paths to default language */}
      <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>
  );
}

function MainLayout() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  // Sync the URL lang param with i18n language
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      navigate(`/${i18n.language}`);
    }
  }, [lang, i18n, navigate]);

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <main role="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="rehome-sell-pets" element={<CreateAdvert />} />
            <Route path="partnership-opportunities" element={<LetsPartner />} />
            <Route path="affiliate" element={<Affiliate />} />
            <Route path="findpets" element={<FindPets />} />
            <Route path="pet-advisor" element={<PetAdvisor />} />
            <Route path="breed-scanner" element={<BreedDetector />} />
            <Route path="videos" element={<VideoPage />} />
            <Route path="pet-calculator" element={<PetCalculator />} />
            <Route path="pet-social" element={<SocialPage />} />


            {/*
              Here is the IMPORTANT part:
              We create a nested route for /pet-classified-ads
              so that your 1-3 segment URLs match FindPetsDetail
            */}
            <Route path="pet-classified-ads">
              {/* If someone goes to /en/pet-classified-ads only, show the FindPets index */}
              <Route index element={<LocationLinks />} />

              {/* 1-level path: /en/pet-classified-ads/:countrySlug */}
              <Route path=":countrySlug" element={<FindPetsDetail />} />
              {/* 2-level path: /en/pet-classified-ads/:countrySlug/:regionSlug */}
              <Route path=":countrySlug/:regionSlug" element={<FindPetsDetail />} />
              {/* 3-level path: /en/pet-classified-ads/:countrySlug/:regionSlug/:citySlug */}
              <Route path=":countrySlug/:regionSlug/:citySlug" element={<FindPetsDetail />} />
            </Route>

            <Route path="/:lang/:country?/:region?/:city?/:slug/:id" element={<PetDetail />} />

            {/* Keep your PetDetail route if you use a different URL pattern for single pet ads */}
            <Route path=":slug/:id" element={<PetDetail />} />

            <Route path="how-to-cancel-subscription-android-and-ios" element={<CancelSubscription />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="terms-of-use" element={<Termsofuse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />

            {/* Fallback inside MainLayout: go to /:lang root */}
            <Route path="*" element={<Navigate to={`/${lang}`} />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default App;
