// BreedInfo.jsx
import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Spinner } from 'react-bootstrap';

const BreedInfo = ({ petType }) => {
    const [breeds, setBreeds] = useState([]);
    const [selectedBreed, setSelectedBreed] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [wikiData, setWikiData] = useState(null);

    // Mock breed data (you can replace with actual API calls)
    const breedData = {
        dog: [
            { name: 'Labrador Retriever', temperament: 'Friendly, Active, Outgoing', size: 'Large' },
            { name: 'German Shepherd', temperament: 'Loyal, Intelligent, Confident', size: 'Large' },
            { name: 'Golden Retriever', temperament: 'Intelligent, Friendly, Devoted', size: 'Large' },
            { name: 'Bulldog', temperament: 'Calm, Courageous, Friendly', size: 'Medium' },
            { name: 'Beagle', temperament: 'Curious, Merry, Friendly', size: 'Small' },
            { name: 'Poodle', temperament: 'Intelligent, Active, Alert', size: 'Varies' },
            { name: 'Rottweiler', temperament: 'Loyal, Loving, Confident Guardian', size: 'Large' },
            { name: 'Yorkshire Terrier', temperament: 'Affectionate, Sprightly, Tomboyish', size: 'Small' },
            { name: 'Boxer', temperament: 'Playful, Patient, Bright', size: 'Large' },
            { name: 'Dachshund', temperament: 'Curious, Friendly, Spunky', size: 'Small' },
            { name: 'Siberian Husky', temperament: 'Outgoing, Alert, Gentle', size: 'Medium' },
            { name: 'Cocker Spaniel', temperament: 'Gentle, Smart, Happy', size: 'Medium' },
            { name: 'Shih Tzu', temperament: 'Affectionate, Playful, Outgoing', size: 'Small' },
            { name: 'Border Collie', temperament: 'Intelligent, Energetic, Responsive', size: 'Medium' },
            { name: 'Chihuahua', temperament: 'Charming, Graceful, Sassy', size: 'Small' },
            { name: 'French Bulldog', temperament: 'Easygoing, Patient, Bright', size: 'Small' },
            { name: 'Cockapoo', temperament: 'Friendly, Affectionate, Intelligent', size: 'Medium' }
        ],
        cat: [
            { name: 'Persian', temperament: 'Sweet, Gentle, Quiet', size: 'Medium' },
            { name: 'Siamese', temperament: 'Social, Intelligent, Vocal', size: 'Medium' },
            { name: 'Maine Coon', temperament: 'Gentle, Playful, Affectionate', size: 'Large' },
            { name: 'Ragdoll', temperament: 'Docile, Affectionate, Placid', size: 'Large' },
            { name: 'Bengal', temperament: 'Energetic, Playful, Intelligent', size: 'Medium' },
            { name: 'British Shorthair', temperament: 'Calm, Affectionate, Loyal', size: 'Medium' },
            { name: 'Sphynx', temperament: 'Inquisitive, Friendly, Energetic', size: 'Medium' },
            { name: 'Scottish Fold', temperament: 'Sweet, Playful, Adaptable', size: 'Medium' },
            { name: 'Russian Blue', temperament: 'Gentle, Intelligent, Shy', size: 'Medium' },
            { name: 'Abyssinian', temperament: 'Active, Curious, Playful', size: 'Medium' },
            { name: 'Burmese', temperament: 'People-oriented, Playful, Intelligent', size: 'Medium' },
            { name: 'Norwegian Forest', temperament: 'Friendly, Calm, Intelligent', size: 'Large' }
        ],
        rabbit: [
            { name: 'Holland Lop', temperament: 'Gentle, Docile, Friendly', size: 'Small' },
            { name: 'Mini Rex', temperament: 'Calm, Curious, Playful', size: 'Small' },
            { name: 'Flemish Giant', temperament: 'Gentle, Calm, Patient', size: 'Large' },
            { name: 'Lionhead', temperament: 'Energetic, Playful, Affectionate', size: 'Small' }
        ],
        guinea_pig: [
            { name: 'American', temperament: 'Social, Gentle, Curious', size: 'Small' },
            { name: 'Abyssinian', temperament: 'Active, Playful, Vocal', size: 'Small' },
            { name: 'Peruvian', temperament: 'Calm, Affectionate, Gentle', size: 'Small' }
        ],
        bird: [
            { name: 'Parakeet (Budgie)', temperament: 'Social, Playful, Vocal', size: 'Small' },
            { name: 'Cockatiel', temperament: 'Affectionate, Intelligent, Playful', size: 'Small' },
            { name: 'Lovebird', temperament: 'Active, Curious, Social', size: 'Small' },
            { name: 'African Grey', temperament: 'Intelligent, Sensitive, Social', size: 'Medium' }
        ],
        hamster: [
            { name: 'Syrian', temperament: 'Solitary, Curious, Active', size: 'Small' },
            { name: 'Roborovski', temperament: 'Energetic, Fast, Social', size: 'Small' },
            { name: 'Winter White', temperament: 'Gentle, Active, Social', size: 'Small' }
        ]
    };

    const fetchWikiInfo = async (breedName) => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://en.wikipedia.org/api/rest_v1/page/summary/${breedName}`
            );
            const data = await response.json();
            setWikiData(data);
        } catch (error) {
            console.error('Error fetching Wikipedia data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (petType && breedData[petType.toLowerCase()]) {
            setBreeds(breedData[petType.toLowerCase()]);
        }
    }, [petType]);

    return (
        <div className="breed-info mt-4">
            <h4>Popular {petType} Breeds</h4>
            <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search breeds..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Form.Group>

            <div className="breed-grid">
                {breeds
                    .filter((breed) =>
                        breed.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((breed) => (
                        <Card key={breed.name} className="mb-3">
                            <Card.Body>
                                <Card.Title>{breed.name}</Card.Title>
                                <Card.Text>
                                    <strong>Temperament:</strong> {breed.temperament}
                                    <br />
                                    <strong>Size:</strong> {breed.size}
                                </Card.Text>
                                <Button
                                    variant="info"
                                    size="sm"
                                    onClick={() => {
                                        setSelectedBreed(breed);
                                        fetchWikiInfo(breed.name);
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Card.Body>
                        </Card>
                    ))}
            </div>

            <Modal
                show={selectedBreed !== null}
                onHide={() => {
                    setSelectedBreed(null);
                    setWikiData(null);
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{selectedBreed?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <>
                            {wikiData && (
                                <div>
                                    <img
                                        src={wikiData.thumbnail?.source}
                                        alt={selectedBreed?.name}
                                        className="mb-3"
                                        style={{ maxWidth: '100%' }}
                                    />
                                    <p>{wikiData.extract}</p>
                                </div>
                            )}
                            <h5>Breed Characteristics</h5>
                            <ul>
                                <li><strong>Temperament:</strong> {selectedBreed?.temperament}</li>
                                <li><strong>Size:</strong> {selectedBreed?.size}</li>
                                {/* Add more characteristics */}
                            </ul>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BreedInfo;