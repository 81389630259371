// src/pages/BreedDetector.js
import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Spinner,
    Card,
    Image as RBImage
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,

} from 'firebase/storage';
import {
    addDoc,
    serverTimestamp,
    doc,

    query,
    where,
    orderBy,
    onSnapshot,
    collection
} from 'firebase/firestore';
import { storage, db } from '../firebase'; // Your Firebase configuration
import { UserAuth } from '../context/AuthContext'; // Your auth context
import LoginComponent from '../components/LoginComponent';

const BreedDetector = () => {
    const { t } = useTranslation();
    const { user } = UserAuth(); // Current authenticated user

    // Local state for the upload form
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');

    // State for Firestore documents (each upload becomes a card)
    const [uploads, setUploads] = useState([]);

    // Reference for the hidden file input
    const fileInputRef = useRef(null);

    // --- Subscribe to uploads from Firestore ---
    useEffect(() => {
        if (user) {
            const q = query(
                collection(db, 'new_breed_ai'),
                where('author_Id', '==', user.uid),
                orderBy('createdAt', 'desc')
            );
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const docs = snapshot.docs.map((docSnap) => ({
                    id: docSnap.id,
                    ...docSnap.data()
                }));
                setUploads(docs);
            });
            return () => unsubscribe();
        }
    }, [user]);

    // --- Handle file selection and preview ---
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => setPreviewUrl(reader.result);
        reader.readAsDataURL(file);
    };

    // --- Compress the image via canvas ---
    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const maxWidth = 400;
            const maxHeight = 400;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                // Use window.Image to avoid conflict with the bootstrap component
                const img = new window.Image();
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;
                    if (width > maxWidth || height > maxHeight) {
                        const ratio = Math.min(maxWidth / width, maxHeight / height);
                        width = width * ratio;
                        height = height * ratio;
                    }
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Image compression failed'));
                            }
                        },
                        'image/jpeg',
                        0.85
                    );
                };
                img.onerror = (err) => reject(err);
            };
            reader.onerror = (err) => reject(err);
        });
    };

    // --- Handle image upload ---
    const handleUpload = async () => {
        if (!selectedFile) {
            setError(t('Please select an image file.'));
            return;
        }
        setError('');
        setUploading(true);
        try {
            // Compress the image before upload
            const compressedBlob = await compressImage(selectedFile);
            const fileName = `breed_ai_${Date.now()}.jpg`;
            const storageRef = ref(storage, `new_breed_ai/${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, compressedBlob);

            // Monitor upload progress
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (err) => {
                    setError(err.message);
                    setUploading(false);
                },
                async () => {
                    // Once upload completes, get the download URL
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    // Create a new Firestore document
                    await addDoc(collection(db, 'new_breed_ai'), {
                        imageUrl: downloadURL,
                        prediction: 0, // Pending (analysis in progress)
                        createdAt: serverTimestamp(),
                        author_Id: user.uid,
                        source: "web"
                    });
                    // Reset the form upon successful upload
                    setSelectedFile(null);
                    setPreviewUrl(null);
                    setProgress(0);
                    setUploading(false);
                }
            );
        } catch (err) {
            setError(err.message);
            setUploading(false);
        }
    };

    // --- Cancel selected image ---
    const handleCancel = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setError('');
    };

    // --- Delete an upload (Firestore document and Storage file) ---


    // --- Render view for logged-out users ---
    if (!user) {
        return (
            <>
                <Helmet>
                    <title>{t('breed.meta_title')}</title>
                    <meta name="description" content={t('breed.meta_description')} />
                    <meta name="keywords" content={t('breed.meta_keywords')} />
                </Helmet>
                <Container className="text-center mt-5">

                    <Row>
                        <Col>
                            <h2>{t('breed.h1')}</h2>
                            <p className="text-muted">{t('breed.description')}</p>
                            <div className="mt-4">
                                <LoginComponent />
                            </div>
                        </Col>
                        <Col>  <img
                            loading="lazy"
                            style={{ maxWidth: '300px', height: 'auto' }}
                            className="img-fluid rounded mb-4 center"
                            src="../assets/web/breedscanner.jpg"
                            alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                        /></Col>
                    </Row>




                </Container>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{t('breed.meta_title')}</title>
                <meta name="description" content={t('breed.meta_description')} />
                <meta name="keywords" content={t('breed.meta_keywords')} />
            </Helmet>
            <Container className="mt-5">
                <Row>
                    <h2>{t('breed.h1')}</h2>
                    <p className="text-muted">{t('breed.description')}</p>
                    {/* Left Column: Upload Form */}
                    <Col md={4}>
                        {uploads.length < 2 ? (
                            <div className="border p-4 rounded mb-4">
                                {/* <h4 className="mb-3">{t('Upload New Image')}</h4> */}
                                {!previewUrl && (
                                    <div className="text-center">
                                        {/* Hidden file input */}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                        <Button variant="primary" onClick={() => fileInputRef.current.click()}>
                                            {t('Upload Pet Image')}
                                        </Button>
                                    </div>
                                )}
                                {previewUrl && (
                                    <div className="text-center">
                                        <RBImage
                                            src={previewUrl}
                                            rounded
                                            fluid
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                        <div className="mt-3">
                                            {!uploading && (
                                                <>
                                                    <Button variant="success" onClick={handleUpload} className="mr-2">
                                                        {t('Upload Image')}
                                                    </Button>
                                                    <Button variant="secondary" onClick={handleCancel}>
                                                        {t('Cancel')}
                                                    </Button>
                                                </>
                                            )}
                                            {uploading && (
                                                <div className="mt-3">
                                                    <Spinner animation="border" size="sm" /> {t('Uploading')} ({progress}%)
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {error && (
                                    <Alert variant="danger" className="mt-3">
                                        {error}
                                    </Alert>
                                )}
                            </div>
                        ) : (
                            <Alert variant="warning">
                                {t("You've reached your free upload limit. For more predictions, please download our app.")} <br />
                                {/* <Button
                                    variant="outline-primary"
                                    href="https://play.google.com/store/apps/details?id=com.app.petshome"
                                    target="_blank"
                                    className="mr-2"
                                >
                                    {t('Download for Android')}
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    href="https://apps.apple.com/us/app/pets-home-app/id1549828591"
                                    target="_blank"
                                >
                                    {t('Download for iOS')}
                                </Button> */}
                            </Alert>
                        )}
                    </Col>

                    {/* Right Column: Card Listing (3 per row) */}
                    <Col md={8}>
                        <Row>
                            {uploads.map((upload) => (
                                <Col key={upload.id} sm={12} md={4} className="mb-4">
                                    <Card>
                                        <Card.Img
                                            variant="top"
                                            src={upload.imageUrl}
                                            style={{ height: '200px', objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            {upload.prediction === 0 || !upload.analysis ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" size="sm" /> {t('Analysis in progress...')}
                                                </div>
                                            ) : (
                                                <>
                                                    <Card.Title>
                                                        {upload.analysis.species} - {upload.analysis.breed}
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <p className="mb-2">
                                                            <strong>{t('Origin')}:</strong> {upload.analysis.origin || '-'}
                                                        </p>
                                                        <p className="mb-2">
                                                            <strong>{t('Vaccination Required')}:</strong>{' '}
                                                            {upload.analysis.vaccinationRequired && Array.isArray(upload.analysis.vaccinationRequired) ? (
                                                                <ul className="list-unstyled mb-0">
                                                                    {upload.analysis.vaccinationRequired.map((item, index) => (
                                                                        <li key={index}>{item}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                upload.analysis.vaccinationRequired || '-'
                                                            )}
                                                        </p>
                                                        <p className="mb-2">
                                                            <strong>{t('Caring Tips')}:</strong>{' '}
                                                            {upload.analysis.caringTips && Array.isArray(upload.analysis.caringTips) ? (
                                                                <ul className="list-unstyled mb-0">
                                                                    {upload.analysis.caringTips.map((item, index) => (
                                                                        <li key={index}>{item}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                upload.analysis.caringTips || '-'
                                                            )}
                                                        </p>
                                                        <p className="mb-2">
                                                            <strong>{t('Estimated Price Range')}:</strong> {upload.analysis.estimatedPriceRange || '-'}
                                                        </p>
                                                    </Card.Text>

                                                </>
                                            )}
                                        </Card.Body>

                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BreedDetector;
