// SocialPage.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { UserAuth } from '../context/AuthContext';
import SocialProfileForm from './../components/SocialProfileForm';
import ProfileFilters from './../components/ProfileFilters';
import ProfileCards from './../components/ProfileCards';
import LoginComponent from '../components/LoginComponent';

import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';


function SocialPage() {
    const { lang } = useParams();
    const { t } = useTranslation();
    const { user } = UserAuth();
    const [profiles, setProfiles] = useState([]);
    const [filters, setFilters] = useState({ tag: '' });

    // Fetch social profiles from your API
    useEffect(() => {
        axios
            .get('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/social')
            .then((response) => setProfiles(response.data))
            .catch((error) => console.error('Error fetching profiles: ', error));
    }, []);

    console.log(setProfiles);

    // Handle submission of a new social profile by a logged in user
    const handleProfileSubmit = async (formData) => {
        try {
            const response = await axios.post(
                'https://api.example.com/social-profiles',
                formData
            );
            // Prepend the new profile to the list
            setProfiles([response.data, ...profiles]);
        } catch (error) {
            console.error('Error submitting profile: ', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("Social Pet Community: Connect with Trainers, Pet Sitters, & More")}
                </title>
                <meta
                    name="description"
                    content={t("Join our Social Pet Community to connect with expert pet trainers, caring pet sitters, reliable dog walkers, and other pet professionals. Create your profile, browse local experts, and build a network dedicated to pet care and social engagement.")}
                />
            </Helmet>
            <Container className="mt-4">
                {/* Header for SEO and page explanation */}
                <header className="text-center my-4">
                    <h1>{t("Social Pet Community")}</h1>
                    <p>
                        {t("Welcome to our Social Pet Community! Here you can connect with expert pet trainers, trusted pet sitters, enthusiastic dog walkers, and more. Whether you’re looking for professional pet services or want to share your own pet expertise, create your social profile and join a vibrant network dedicated to quality pet care and social engagement.")}
                    </p>
                </header>
                <Row>
                    {/* Left Column: Create Profile form for logged in users */}
                    <Col md={4}>
                        {/* <h4>Create Your Social Profile</h4> */}
                        {/* {!user ? (
                            <LoginComponent />
                        ) : (
                            <SocialProfileForm
                                onSubmit={handleProfileSubmit}
                                className="rounded bg-light p-2 mb-2"
                            />
                        )} */}




                        <img
                            loading="lazy"
                            style={{ maxWidth: '300px', height: 'auto' }}
                            className="img-fluid rounded mb-4 center"
                            src="/assets/web/pet-connect.png"
                            alt="Rehome Pets Near You | Find Loving Homes for Dogs, Cats, and More | Pets Home App"
                        />   <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                        <div className="d-flex justify-content-center">
                            <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                                <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                            </a>
                            <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                                <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                            </a>
                        </div>
                        <div className="d-flex justify-content-center">
                            {/* <small> {t('rated')} </small> */}
                            <div>
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                            <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                        </div>
                        <p className="text-center mt-3 d-none d-md-block">{t('Scan the QR codes below for quick access:')}</p>


                        {/* Social Sharing Buttons */}
                        <div className="social-sharing text-center my-4">
                            <p>{t('global.share_p')}</p>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" className="mx-2" />
                            </a>
                            <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Sell%20Your%20Pet%20Online%20for%20Free%20with%20Pets%20Home%20App" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="2x" className="mx-2" />
                            </a>
                            <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" className="mx-2" />
                            </a>
                            <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faPinterest} size="2x" className="mx-2" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="2x" className="mx-2" />
                            </a>
                        </div>

                    </Col>

                    <Col md={8}>
                        <ProfileFilters filters={filters} setFilters={setFilters} />
                        <ProfileCards profiles={profiles} filters={filters} />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SocialPage;
